import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import WigLoader from "@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader"
import config from "../../config.json"

require("es6-promise").polyfill()

export default function SinglePage(pageProps) {
  const {
    pageContext: { integratorData, contentMetaData, contentDataData },
    props,
  } = pageProps

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentMetaData.seoTitle}</title>
        <meta name="description" content={contentMetaData.metaDescription} />
      </Helmet>

      <div className="devonshire-group">
        <section className="app-content">
          <div
            className="main-banner"
            style={{
              backgroundImage:
                "url(" + contentDataData[0].children[0].value + ")",
            }}
          ></div>
          <div className="container">
            <div className="page-main-container-wrap">
              <div className="row">
                <div className="col-12">
                  <div className="main-intro">
                    <h1>{contentDataData[0].children[1].value}</h1>
                    <a
                      href={
                        pageProps.path.includes("/derbyshire-estate")
                          ? "/derbyshire-estate/vacancies/"
                          : pageProps.path.includes("/yorkshire-estate")
                          ? "/yorkshire-estate/vacancies/"
                          : pageProps.path.includes("/irish-estate")
                          ? "/irish-estate/vacancies/"
                          : pageProps.path.includes(
                              "/devonshire-group/work-experience"
                            )
                          ? "/devonshire-group/work-experience/vacancies/"
                          : pageProps.path.includes("/irish-estate")
                          ? "/irish-estate/vacancies/"
                          : pageProps.path.includes("/hotels-and-restaurants")
                          ? "/hotels-and-restaurants/vacancies/"
                          : pageProps.path.includes(
                              "/devonshire-group/volunteering"
                            )
                          ? "/devonshire-group/volunteering/vacancies/"
                          : "/devonshire-group/vacancies/"
                      }
                      className="btn btn-primary-inverted btn-small"
                    >
                      View opportunities
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {contentDataData[1].children[0].children.map((column, i) => (
        <div key={i} className="main-container-wrap">
          <div
            className="page-hero-container-wrap container-fluid"
            style={{
              backgroundColor:
                contentDataData[1].children[0].children[i][4].value,
            }}
          >
            <div className="container">
              <div className="page-main-container-wrap">
                {contentDataData[1].children[0].children[i][0].value !==
                  undefined && (
                  <div className="container-wrap-header">
                    <h3>
                      {contentDataData[1].children[0].children[i][0].value}
                    </h3>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    {contentDataData[1].children[0].children[i][2].value !==
                    undefined ? (
                      <img
                        src={
                          contentDataData[1].children[0].children[i][2].value
                        }
                        alt={contentMetaData.seoTitle}
                        className="float-right"
                      />
                    ) : null}
                    <h2>
                      {contentDataData[1].children[0].children[i][1].value}
                    </h2>
                    <WigLoader
                      contentDataData={contentDataData}
                      contentMetaData={contentMetaData}
                      contentDataArray="1"
                      contentDataChild="0"
                      customDataArray={column[3].value}
                      contentDataChildChildChild="1"
                      AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                      AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                      integratorID={config.integratorID}
                      integratorAccessToken={config.integratorAccessToken}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="top">
        <div className="container" style={{ margin: "0px auto" }}>
          <div id="job-alerts" className="row alerts">
            <div className="col-md-12 col-12">
              <div className="job-alert-wrap">
                <span>Keep up to date with the latest jobs from us</span>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="button-wrap">
                <Link
                  to="/job-alerts"
                  type="button"
                  className="btn btn-primary btn-jobalerts"
                >
                  SUBSCRIBE TO JOB ALERTS{"   "}
                  <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
